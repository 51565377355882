import React from "react";
import "./experience.css";

import { FaCheckCircle } from "react-icons/fa";

const Experience = () => {
  return (
    <section id="experience">
      <h4>What skills do I have?</h4>
      <h2>My Experience</h2>
      <div className="container experience__container">
        {/*=================== FRONTEND ===================*/}
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Experineced</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>Bootstrap</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>React</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>Angular</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
          </div>
        </div>
        {/*=================== BACKEND ===================*/}
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>GoLang</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>Python</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>Java</h4>
                <small className="text-light">Experineced</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>MongoDB</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>MySQL</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>Postgres</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
          </div>
        </div>

        {/*=================== DATABASE ===================*/}

        {/*=================== SYSTEM ===================*/}
        <div className="experince__system_administration">
          <h3>System Administration</h3>
          <div className="experience__content">
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>Windows Administration</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheckCircle className="experience__details-icon" />
              <div>
                <h4>Linux Administration</h4>
                <small className="text-light">Intermidate</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
