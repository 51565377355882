import React, { useState, useEffect } from "react";
import "./portfolio.css";
import IMG1 from "../../assets/phonebook-site-prototype.jpg";
import IMG2 from "../../assets/mobile-application.jpg";
import IMG3 from "../../assets/banking-app-test.jpg";
import IMG4 from "../../assets/website-development-process.jpg";

// import Swiper core and required modules
// import { Pagination } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

// DATAmap

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Phonebook",
    github: "https://github.com",
    demo: "https://youtube.com",
  },
  {
    id: 2,
    image: IMG2,
    title: "Mobile",
    github: "https://github.com",
    demo: "https://youtube.com",
  },
  {
    id: 3,
    image: IMG3,
    title: "Banking",
    github: "https://github.com",
    demo: "https://youtube.com",
  },
  {
    id: 4,
    image: IMG4,
    title: "Phonebook",
    github: "https://github.com",
    demo: "https://youtube.com",
  },
  // TODO: ADD OTHER 3 elements
];

const Portfolio = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    handleResize(); // Initialize with the current window size

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (!isMobile) {
    return (
      <section id="portfolio">
        <h3>My Recent Work</h3>
        <div className="container portfolio__container">
          {data.map(({ id, image, title, github, demo }) => {
            return (
              <article key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github}></a>
                  <a href={demo}></a>
                </div>
              </article>
            );
          })}
        </div>
      </section>
    );
  } else {
    return (
      <section id="portfolio">
        <h5>My Recent Work</h5>
        <Swiper className="container portfolio__container">
          {data.map(({ id, image, title, github, demo }) => {
            return (
              <SwiperSlide key={id} className="portfolio__item">
                <div className="portfolio__item-image">
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github}></a>
                  <a href={demo}></a>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </section>
    );
  }
};

export default Portfolio;
