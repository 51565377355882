import React from "react";
import { TfiEmail } from "react-icons/tfi";
import { LuFacebook } from "react-icons/lu";
import { FaWhatsapp } from "react-icons/fa";

import { useRef } from "react";
import emailjs from "@emailjs/browser";

import "./contact.css";
const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_xd9r20i",
        "template_71ltd7j",
        form.current,
        "gZR0cU_EvPrqV-Qgl"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section id="contact">
      {" "}
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <TfiEmail className="contact__option-icon" />
            <h4>Gmail</h4>

            <a
              href="mailto:insendkost@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              Send an email
            </a>
          </article>
          <article className="contact__option">
            <LuFacebook className="contact__option-icon" />
            <h4>Social</h4>

            <a
              href="https://www.facebook.com/insendkost"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
          </article>
          <article className="contact__option">
            <FaWhatsapp className="contact__option-icon" />
            <h4>What'sApp</h4>

            <a
              href="https://wa.me/972503082819"
              target="_blank"
              rel="noreferrer"
            >
              Let's chat
            </a>
          </article>
        </div>
        {/* END OF CONTACT OPTIONS */}
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Name please"
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Your email please"
            required
          />
          <textarea
            name="message"
            rows="7"
            placeholder="Your message"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary" Send Message>
            Send
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
